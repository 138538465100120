import React, {useEffect, useState} from 'react';

import {Route, Switch, useHistory} from 'react-router-dom';
import {Box} from 'rebass';
import {ThemeProvider} from '@emotion/react';
import {Font} from '@react-pdf/renderer';

import {Toast} from '@renofi/components';
import {chilipiper} from '@renofi/analytics';
import {useLocalStorage} from '@renofi/utils';
import {breakpoint} from '@renofi/utils/src/mediaQuery';

import './App.css';
import {NavigationBar, NavigationItem} from '../components';
import Calculator from '../Calculator';
import RmppEligibleStates from '../RmppEligibleStates';
import Pdf from '../Pdf';
import CalculatorsList from '../Calculator/CalculatorsList';
import WelcomeModal from '../WelcomeModal';
import OfficerFormModal from '../OfficerFormModal';
import averta from '../Pdf/fonts/39E1A3_A_0.ttf';
import avertaStrong from '../Pdf/fonts/39E1A3_C_0.ttf';

import {GuidelineLink} from './styled';
import {ReactComponent as DownloadIcon} from './img/download.svg';

const theme = {
  breakpoints: [`${breakpoint}px`],
};

function App() {
  const [guidelines] = useLocalStorage('guidelines');
  const [showModal, setShowModal] = useState(!guidelines);
  const history = useHistory();

  useEffect(() => {
    Font.register({family: 'Averta', src: averta});
    Font.register({
      family: 'Averta',
      fonts: [{src: averta}, {src: avertaStrong, fontWeight: 'bold'}],
    });
  });

  function onSchedule(mloDetails) {
    history.goBack();
    chilipiper.initSchedule({
      lead: mloDetails,
      analyticsPrefix: 'MLO',
      router: 'partnerships---loan-officers',
    });
  }

  return (
    <ThemeProvider theme={theme}>
      <Box minHeight={['100%', 'auto']} bg={['white', 'transparent']}>
        <Switch>
          <Route exact path="/pdf">
            <Pdf />
          </Route>
          <Route path="/">
            <NavigationBar>
              <NavigationItem url="/calculators" title="Calculator" />
              <a
                href="https://assets.renofi.com/rmpp/RenoFi%20Marketing%20Partnership%20Program%20Guide.pdf"
                rel="noreferrer"
                style={{
                  color: '#fff',
                  textDecoration: 'none',
                }}
                target="_blank">
                <GuidelineLink>
                  {' '}
                  Guidelines
                  <DownloadIcon style={{width: 24, marginLeft: 7}} />
                </GuidelineLink>
              </a>
            </NavigationBar>

            <Switch>
              <Route exact path="/calculators">
                <CalculatorsList />
              </Route>

              <Route path="/calculators/:id?">
                <Calculator onInfoClick={() => setShowModal(true)} />
              </Route>

              <Route path="/rmpp/states">
                <RmppEligibleStates onInfoClick={() => setShowModal(true)} />
              </Route>

              <Route exact path="/">
                <CalculatorsList />
              </Route>
            </Switch>
          </Route>
        </Switch>

        <Toast />

        <WelcomeModal show={showModal} onClose={() => setShowModal(false)} />

        <Route path="/signup">
          <OfficerFormModal onSubmit={onSchedule} />
        </Route>
      </Box>
    </ThemeProvider>
  );
}

export default App;
